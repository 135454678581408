import React from "react";
import dotUi from "../../dist/images/Dot_Grid.svg";
import PrimaryButton from "../constants/buttons/PrimaryButton";
import { useState } from "react";
import SignInput from "../constants/buttons/SignInput";
import TopBar from "../constants/TopBar";
import { SERVER_URL } from "../../config";


const ResetPassword = () => {

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
 

  const [formData, setFormData] = useState({
    new_password: '',
    confirm_new_password: '',
  });

  const handleChange = (e) => {
    setError("")
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async () => {
   
  
    if (formData.new_password === formData.confirm_new_password && validatePassword(formData.new_password)) {
        document.getElementById("spinner").style.display = "grid";
        document.getElementById("card").style.display = "none"
        document.getElementById("card-parent").style.left = "17rem";
        document.getElementById("card-parent").style.top = "10rem";
        try {
        const response = await fetch(`${SERVER_URL}api/accounts/reset-password/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            new_password: formData.new_password,
            token: token,
          }),
        });
        document.getElementById("spinner").style.display = "none";
        document.getElementById("card").style.display = "flex"
        document.getElementById("card-parent").style.left = "4rem"
        document.getElementById("card-parent").style.top = "3rem"
        setFormData({new_password:"", confirm_new_password:""})
        if (response.ok) {
          const data = await response.json();
          setMessage(data.message);
        } else {
          const data = await response.json();
          setError(data.error || 'An unexpected error occurred.');
        }
      } catch (error) {
        console.error('An unexpected error occurred:', error);
        setError('An unexpected error occurred.');
      }
    } else {
      setError('Password does not match or does not meet the criteria, or password does not match');
    }
  };

  return (
    <div className="px-2 md:px-5 py-7 sm:ml-64">
      <section className="mb-7">
        <TopBar title="Change Password" />
      </section>
      <section className="sign">
        <div className="inner-box-2 md:absolute">
          <img
            className="dotui-1 md:absolute bottom-0 right-0 hidden md:block"
            src={dotUi}
            alt=""
          />
          <img className="dotui-2 hidden md:block" src={dotUi} alt="" />
          <div id="card-parent" className="sign-form md:absolute md:top-16 md:left-16">
          <div className="spinner" id="spinner"></div>
            <div id="card"  className="Card w-96 p-6 bg-white rounded-2xl flex-col justify-start items-start gap-8 inline-flex">
              <div className="Text self-stretch flex-col justify-start items-start gap-2 flex">
                <div className="SignIn self-stretch text-slate-950 text-base font-['SF Pro']">
                  Change Password
                </div>
                <div className="self-stretch text-slate-400 text-sm font-normal font-['SF Pro'] leading-7">
                {message && <div className="text-green-500">{message}</div>}
                {error && <div className="text-red-500">{error}</div>}
                </div>
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                <SignInput
                  type="password"
                  name="new_password"
                  placeholder="New Password"
                  value={formData.new_password}
                  onChange={handleChange}
                />
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                <SignInput
                  type="password"
                  name="confirm_new_password"
                  placeholder="Confirm New Password"
                  value={formData.confirm_new_password}
                  onChange={handleChange}
                />
              </div>
              <button onClick={handleSubmit}>
                  <PrimaryButton primaryBtn="Submit" />
                </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResetPassword;
