import React, { useEffect, useState } from "react";
import PrimaryButton from "../../constants/buttons/PrimaryButton";
import { Link } from "react-router-dom";
import TopBar from "../../constants/TopBar";
import { isTokenExpired } from '../../authutils';
import { SERVER_URL } from "../../../config";
import { createBrowserHistory } from 'history';


const Plans = ({ set_amount,setProduct_id }) => {
  const [products, setProducts] = useState([]);
  const history = createBrowserHistory();
  useEffect(() => {
    const isUserLogin = isTokenExpired();

    if (isUserLogin) {
      history.push("/sign_in");
      window.dispatchEvent(new Event("popstate"));
    }
    const checkShowPricingButton = async () => {
      try {
         // Replace with your actual authentication token
        const response = await fetch(`${SERVER_URL}api/payments/check_payment`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            'Content-Type': 'application/json', // Adjust content type as needed
          },
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const data = await response.json();
        if (data){
          history.push("/sign_in");
          window.dispatchEvent(new Event("popstate"));
        }; // Assuming your API returns a boolean value
      } catch (error) {
        console.error('Error checking showPricingButton:', error);
      }
    };
    checkShowPricingButton();
    }, []);
 
  const setProductId = (id,price) => {

    setProduct_id(id);
    set_amount(price);

    // other logic if needed
  };
  
  useEffect(() => {
    const isUserLogin = isTokenExpired();
    if (isUserLogin) {
      history.push("/sign_in");
      window.dispatchEvent(new Event("popstate"));
    }
    
    const fetchProducts = async () => {
      try {
        const stripeProductsEndpoint = `${SERVER_URL}api/payments/get_products`;
        const response = await fetch(stripeProductsEndpoint, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });

        if (response.ok) {
          const productsData = await response.json();
          // Adjust the response format to match your component state
          const formattedProducts = productsData.products.map(product => ({
            name: product.name,
            price: `${product.default_price}`,
            description: product.description || 'No description available',
            product_price_id:product.product_price_id
          }));
          setProducts(formattedProducts);
        } else {
          console.error(`Error: ${response.status} - ${response.statusText}`);
        }
      } catch (error) {
        console.error(`Error: ${error.message}`);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="px-2 md:px-5 py-7 sm:ml-64">
      <section className="mb-7">
        <TopBar heading="Pricing" title="Plans" />
      </section>
      <section className="plans">
      {products.length > 0  ? (
        products.map((product, index) => (
          <div key={index} className="plans-box">
            <div key={product.id} className="Card shadow-lg w-78 px-6 py-10 bg-white rounded-2xl flex-col justify-start items-start gap-8 inline-flex">
              <div className="">
                <div className="self-stretch text-slate-950 text-2xl font-['SF Pro']">
                  {product.name}
                </div>
                <div className="self-stretch text-slate-950 text-base font-['SF Pro']">
                  ${product.price}
                </div>
                <div className="mt-6">
                  <div className="self-stretch text-slate-950 text-lg font-['SF Pro']">
                    Includes:
                  </div>
                  <div className="self-stretch leading-6 text-slate-400 text-sm font-normal font-['SF Pro'] leading-7">
                    {product.description}
                  </div>
                </div>
              </div>
              <div>
                <Link to="/pricing/payment">
                  <PrimaryButton primaryBtn="Choose Plan" onclick={()=>setProductId(product.product_price_id, product.price)} />
                </Link>
               
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="spinner" style={{display:'block'}} id="spinner"></div>
      )}
      </section>
    </div>
  );
};

export default Plans;
