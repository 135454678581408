const Select = (props) => {
  const hasOptions = Array.isArray(props.options) && props.options.length > 0;
  
  const handleChange = (e) => {
    props.onChange(e);
  };

  // Sort the options alphabetically
  var Options = props.options
  if (props.label === "County" || props.label === "City" || props.label === "State"){

    Options = hasOptions ? props.options.slice().sort((a, b) => a.label.localeCompare(b.label)) : [];
  }

  return (
    <>
      <div className="w-56 h-20 flex-col justify-start items-start gap-4 flex mb-8">
        <div className="City self-stretch text-slate-400 text-xs uppercase">
          {props.label}
        </div>

        <select disabled={props.disabled} id="countries" className="select-btn" onChange={handleChange} value={props.value} >
         
          {hasOptions ? (
            Options.map((option, index) => (
              <option key={index} value={option.value} selected={(option.label === props.value) ? option.label : ""}>
                {option.label}
              </option>
            ))
          ) : (
            <option value={props.value}>{props.value}</option>
          )}
        </select>
      </div>
    </>
  );
}

export default Select;
