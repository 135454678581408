import React from "react";
import dotUi from "../../dist/images/Dot_Grid.svg";
import google from "../../dist/images/Google.svg";
import PrimaryButton from "../constants/buttons/PrimaryButton";
import { useState,useEffect } from "react";
import SignInput from "../constants/buttons/SignInput";
import { isTokenExpired } from "../authutils";
import TopBar from "../constants/TopBar";
import { API_URL, SERVER_URL } from "../../config";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { createBrowserHistory } from 'history';
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";


const SignIn = () => {

  const [ user, setUser ] = useState([]);
  const history = createBrowserHistory();
  const location = useLocation();
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
});
  useEffect(() => {
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    return axios.post(`${SERVER_URL}api/accounts/google/siginsignup/`, res.data, {
                    headers: {
                      Accept: 'application/json'
                            }
                        });
                    })
                    .then(response => {
                      localStorage.setItem('accessToken', response.data.access);
                      history.push( '/app_demo');
                      window.dispatchEvent(new Event('popstate')); 
                      window.location.reload();
                    })
                .catch((err) => console.log(err));
        }
    },[user]);
  
  useEffect(() => {
    const isUserLogin = isTokenExpired();

    if (!isUserLogin) {
      history.push( '/app_demo');
      window.dispatchEvent(new Event('popstate'));
    }
  }, []); 

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setError("")
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSignIn = async () => {
    try {
      const response = await fetch(`${SERVER_URL}api/accounts/signin/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email, // Assuming the API uses 'username' for email
          password: formData.password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('accessToken', data.access);
        history.push('/app_demo');
        window.dispatchEvent(new Event('popstate')); 
        window.location.reload();
      } else {
        const data = await response.json();
        setError(data.error || 'An unexpected error occurred.');
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error);
      setError('An unexpected error occurred.');
    }
  };


  return (
    <div className="px-2 md:px-5 py-7 sm:ml-64">
      <section className="mb-7">
        <TopBar heading="Existing User" title="Sign In" />
      </section>
      <section className="sign">
        <div className="inner-box-2 md:absolute">
          <img
            className="dotui-1 md:absolute bottom-0 right-0 hidden md:block"
            src={dotUi}
            alt=""
          />
          <img className="dotui-2 hidden md:block" src={dotUi} alt="" />
          <div className="sign-form md:absolute md:top-16 md:left-16">
            <div className="Card w-96 p-6 bg-white rounded-2xl flex-col justify-start items-start gap-8 inline-flex">
              <div className="Text self-stretch flex-col justify-start items-start gap-2 flex">
                <div className="SignIn self-stretch text-slate-950 text-base font-['SF Pro']">
                  Sign In
                </div>
                <div className="self-stretch text-slate-400 text-sm font-normal font-['SF Pro'] leading-7">
                {error && <div className="text-red-500">{error}</div>}
                </div>
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                <SignInput
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleChange}
                />
                <SignInput
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
              <div className="Row self-stretch justify-start items-center gap-6 inline-flex">
              <button onClick={handleSignIn}>
                  <PrimaryButton primaryBtn="Continue" />
                </button>
                <div onClick={() => login()} className="grow shrink basis-0 h-6 justify-end items-center gap-2 flex cursor-pointer">
                  <img src={google} alt="" />
                  <div className="text-center text-slate-950 text-sm">
                    Continue Using Google
                  </div>
                </div>
              </div>
              <Link to="/forget/password" style={{textDecoration:"underline", color: "#6366f1"}}>forget password?</Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignIn;
