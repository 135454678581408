import { jwtDecode as jwt_decode } from 'jwt-decode';


const isTokenExpired = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      return true; 
    }
  
    try {
      const decodedToken = jwt_decode(accessToken);
      const currentTime = Math.floor(Date.now() / 1000);
      return decodedToken.exp < currentTime;
    } catch (error) {
      return true; 
    }
  };
  
  export { isTokenExpired };