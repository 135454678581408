import React from "react";
import Slider from "./Slider";
import Input from "./buttons/Input";
import Select from "./buttons/Select";
import PrimaryButton from "./buttons/PrimaryButton";
import SecondaryInput from "./buttons/SecondaryInput";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { API_URL, SERVER_URL } from "../../config";

const   Filter = (props) => {
    const [localFilterData, setLocalFilterData] = useState({});
    const [buyboxname, setBuyBoxName] = useState(props.buy_box_name);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [counties, setCounties] = useState([]);
    const [citydisable, setCitydisable] = useState(true);
    const [countydisable, setCountydisable] = useState(true);
    useEffect(() => {
       
        if (props.buy_box_name){
            setBuyBoxName(props.buy_box_name);
    
        }
    
    }, [props.buy_box_name]);


      const fetchStatesData = async () => {
        try {
            const response = await fetch(`${SERVER_URL}api/script/get-unique-states`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data.states);
            setStates(data.states)
           
        } catch (error) {
            console.error('Error fetching states', error);
        }
    };

    const fetchCitiesData = async (state) => {
        try {
            const response = await fetch(`${SERVER_URL}api/script/get-unique-cities/${state}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
          
            setCities(data.cities)
           
        } catch (error) {
            console.error('Error fetching states', error);
        }
    };

    const fetchCountyData = async (city) => {
        try {
            const response = await fetch(`${SERVER_URL}api/script/get-unique-counties/${city}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
          
            setCounties(data.counties)
           
        } catch (error) {
            console.error('Error fetching states', error);
        }
    };
    const fetchDefaultBuyBox = async () => {
        try {
            const response = await fetch(`${SERVER_URL}api/script/get-default-box/`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setLocalFilterData(data.default.script_params);
            console.log(data.default.script_params);
            setBuyBoxName(data.default.buy_box_name);
        } catch (error) {
            console.error('Error fetching default buy box:', error);
        }
    };
    useEffect(() => {
        fetchDefaultBuyBox();
        fetchStatesData();

    }, []);
    const handleDelete = () => {
        fetch(`${SERVER_URL}api/script/delete-script-params/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
            body: JSON.stringify({ "buy_box_name": props.buy_box_name }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                fetchDefaultBuyBox();
                setBuyBoxName('default');
                props.onDelete();
                return response.json();
            })
            .then(data => {
                
            })
            .catch(error => {
            });
    };

    const [isdemo, setdemo] = useState(props.demo);

    const updateFilter = (key, value) => {
        console.log(key, value);
        if (key === 'state') {
            fetchCitiesData(value);
            setCitydisable(false);
        } else if (key === 'city') {
            fetchCountyData(value);
            setCountydisable(false);
        }
        
        setLocalFilterData((prevData) => {
            const newFilterData = { ...prevData, [key]: value};
            return newFilterData;
        });
    };
    const deleteScriptParams = () => {
        document.getElementById('buy_box_name').value = "";
        document.getElementById('search_box_name').innerHTML = "";
        setLocalFilterData({});
        
    }
    const areObjectsEqual = (obj1, obj2) => {
        const stringifiedObj1 = JSON.stringify(obj1);
        const stringifiedObj2 = JSON.stringify(obj2);

        return stringifiedObj1 === stringifiedObj2;
    };
    useEffect(() => {
        if (!areObjectsEqual(localFilterData, props.dataOfBuyBox)) {
            if (Array.isArray(props.dataOfBuyBox) && props.dataOfBuyBox.length > 0) {
                setLocalFilterData(props.dataOfBuyBox);
            } else if (typeof props.dataOfBuyBox === 'object' && Object.keys(props.dataOfBuyBox).length > 0) {
                setLocalFilterData(props.dataOfBuyBox);
            } else {

            }
        }
    }, [props.dataOfBuyBox]);

    const currentLocation = useLocation();
    const handleBuyBoxInput = (event) => {
        localFilterData.name=event.target.value
        setLocalFilterData(localFilterData);
        console.log(localFilterData);
        setBuyBoxName(event.target.value);
    };

    useEffect(() => {
        localFilterData.name=localFilterData?.name
        props.onFilterChange(localFilterData);

    }, []);
    useEffect(() => {

        localFilterData.name=localFilterData?.name
        props.onFilterChange(localFilterData);

    }, [localFilterData]);

    useEffect(() => {
        if (props.onDataBuyBoxChange) {
            // localFilterData.name=buyboxname
            props.onDataBuyBoxChange(localFilterData);
            setBuyBoxName(localFilterData?.name);
        }
    }, [localFilterData, props.onDataBuyBoxChange]);


    const saveConfigurations = (configData) => {
        fetch(`${SERVER_URL}api/script/save_configurations/`, {  // Use your server's URL
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
            body: JSON.stringify({
                ...localFilterData,
                "buy_boxname": buyboxname
            }),

        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                setBuyBoxName(localFilterData?.name)
                props.onSaveConfig(localFilterData?.name)
            })
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.log(error);
            });
    };
    const timeOnMarketOptions = [
        { value: "", label: 'any'},
        { value: '&time_on_market_range=1-', label: 'less than 1 days' },
        { value: '&time_on_market_range=3-', label: 'less than 3 days' },
        { value: '&time_on_market_range=7-', label: 'less than 7 days' },
        { value: '&time_on_market_range=14-', label: 'less than 14 days' },
        { value: '&time_on_market_range=30-', label: 'less than 30 days' },
        { value: '&time_on_market_range=-7', label: 'more than 7 days' },
        { value: '&time_on_market_range=-14', label: 'more than 14 days' },
        { value: '&time_on_market_range=-30', label: 'more than 30 days' },
        { value: '&time_on_market_range=-45', label: 'more than 45 days' },
        { value: '&time_on_market_range=-60', label: 'more than 60 days' },
        { value: '&time_on_market_range=-90', label: 'more than 90 days' },
        { value: '&time_on_market_range=-90', label: 'more than 180 days' },
    ];
    const garages = [
        { value: "", label: 'any' },
        { value: '&pkg=1-', label: '1' },
        { value: '&pkg=2-', label: '2' },
        { value: '&pkg=3-', label: '3' },
        { value: '&pkg=4-', label: '4' },
        { value: '&pkg=5-', label: '5' },
    ];
    const State = [
        { value: 'ohio', label: 'Ohio' },
        { value: 'ohio2', label: 'Ohio2' },
       
    ];
    const zipcodes = [
        { value: '40012', label: '40012' },
        { value: '40013', label: '40013' },
    ];
    const homeTypes = [
        { value: 'house', label: 'House' },
    ];
    const poolTypes = [
        { value: '', label: 'any' },
        { value: '&pool_types=1', label: 'private pool' },
        { value: '&pool_types=2', label: 'Community pool' },
        { value: '&pool_types=3', label: 'private or community pool' },
        { value: '&pool_types=4', label: 'no private pool' },
    ];
    const schoolsRating = [
        { value: '', label: '0/10' },
        { value: '&school_rating=1-', label: '1/10' },
        { value: '&school_rating=2-', label: '2/10' },
        { value: '&school_rating=3-', label: '3/10' },
        { value: '&school_rating=4-', label: '4/10' },
        { value: '&school_rating=5-', label: '5/10' },
        { value: '&school_rating=6-', label: '6/10' },
        { value: '&school_rating=7-', label: '7/10' },
        { value: '&school_rating=8-', label: '8/10' },
        { value: '&school_rating=9-', label: '9/10' },
        { value: '&school_rating=10-', label: '10/10' },
    ];
    const schoolsTypes = [
        { value: '', label: 'any' },
        { value: '&school_types=1-', label: 'elementary school' },
        { value: '&school_types=2-', label: 'middle school' },
        { value: '&school_types=3-', label: 'high school' },
        { value: '&school_types=4-', label: 'elementary and middle' },
        { value: '&school_types=5-', label: 'middle and high' },
        { value: '&school_types=6-', label: 'elementary and high ' },
        { value: '&school_types=7-', label: 'all three' },
    ];
    const percentateOfArv = [
        { value: '0.50', label: '50%' },
        { value: '0.55', label: '55%' },
        { value: '0.60', label: '60%' },
        { value: '0.65', label: '65%' },
        { value: '0.70', label: '70%' },
        { value: '0.75', label: '75%' },
        { value: '0.80', label: '80%' },
        { value: '0.85', label: '85%' },
        { value: '0.90', label: '90%' },
        { value: '0.95', label: '95%' },
        { value: '1.0', label: '100%' },
    ];
    const radiusComp = [
        { value: '0.25', label: '0.25' },
        { value: '0.50', label: '0.5' },
        { value: '0.75', label: '0.75' },
        { value: '1.00', label: '1' },
        { value: '1.25', label: '1.25' },
        { value: '1.50', label: '1.5' },
        { value: '1.75', label: '1.75' },
        { value: '2.00', label: '2' }
    ];
    const sqftlimits = [
        { value: '50', label: '50' },
        { value: '100', label: '100' },
        { value: '150', label: '150' },
        { value: '200', label: '200' },
        { value: '250', label: '250' },
        { value: '300', label: '300' },
        { value: '350', label: '350' },
        { value: '400', label: '400' }
    ];
    const rentmultipliers = [
        { value: '1.00', label: '1' },
        { value: '1.25', label: '1.25' },
        { value: '1.50', label: '1.5' },
        { value: '1.75', label: '1.75' },
        { value: '2.00', label: '2' },
        { value: '2.25', label: '2.25' },
        { value: '2.50', label: '2.5' },
        { value: '2.75', label: '2.75' },
        { value: '3.00', label: '3' }
    ];
    const annualInvestRate = [
        { value: '0.0250', label: '2.5%' },
        { value: '0.0275', label: '2.75%' },
        { value: '0.030', label: '3%' },
        { value: '0.0325', label: '3.25%' },
        { value: '0.0350', label: '3.5%' },
        { value: '0.0375', label: '3.75%' },
        { value: '0.0400', label: '4%' },
        { value: '0.0425', label: '4.25%' },
        { value: '0.0450', label: '4.5%' },
        { value: '0.0475', label: '4.75%' },
        { value: '0.0500', label: '5%' },
        { value: '0.0525', label: '5.25%' },
        { value: '0.0550', label: '5.5%' },
        { value: '0.0575', label: '5.75%' },
        { value: '0.0600', label: '6%' },
        { value: '0.0625', label: '6.25%' },
        { value: '0.0650', label: '6.5%' },
        { value: '0.0675', label: '6.75%' },
        { value: '0.0700', label: '7%' },
        { value: '0.0725', label: '7.25%' },
        { value: '0.0750', label: '7.5%' },
        { value: '0.0775', label: '7.75%' },
        { value: '0.0800', label: '8%' },
        { value: '0.0825', label: '8.25%' },
        { value: '0.0850', label: '8.5%' },
        { value: '0.0875', label: '8.75%' },
        { value: '0.0900', label: '9%' },
        { value: '0.0925', label: '9.25%' },
        { value: '0.0950', label: '9.5%' },
        { value: '0.0975', label: '9.75%' },
        { value: '0.1000', label: '10%' }
    ];
    const loanTermYears = [
        { value: '5', label: '5' },
        { value: '10', label: '10' },
        { value: '15', label: '15' },
        { value: '20', label: '20' },
        { value: '25', label: '25' },
        { value: '30', label: '30' },
        { value: '35', label: '35' },
        { value: '40', label: '40' }
    ];
    const financingOptions = [
    { value: '0.20', label: '20%' },
    { value: '0.40', label: '40%' },
    { value: '0.60', label: '60%' },
    { value: '0.80', label: '80%' },
    { value: '0.95', label: '95%' },
    { value: '1.00', label: '100%' }
    ];
    const purchaseCostOptions = [
        { value: '0.05', label: '5%' },
        { value: '0.06', label: '6%' },
        { value: '0.07', label: '7%' },
        { value: '0.08', label: '8%' },
        { value: '0.09', label: '9%' },
        { value: '0.1', label: '10%' },
        { value: '0.11', label: '11%' }
    ];
    const operatingExpensesOptions = [
        { value: '0.1', label: '10%' },
        { value: '0.15', label: '15%' },
        { value: '0.2', label: '20%' },
        { value: '0.25', label: '25%' },
        { value: '0.3', label: '30%' },
        { value: '0.35', label: '35%' },
        { value: '0.4', label: '40%' },
        { value: '0.45', label: '45%' },
        { value: '0.5', label: '50%' }
    ];
    const vacancyCostOptions = [
        { value: '0.01', label: '1%' },
        { value: '0.02', label: '2%' },
        { value: '0.03', label: '3%' },
        { value: '0.04', label: '4%' },
        { value: '0.05', label: '5%' },
        { value: '0.06', label: '6%' },
        { value: '0.07', label: '7%' },
        { value: '0.08', label: '8%' },
        { value: '0.09', label: '9%' },
        { value: '0.1', label: '10%' }
    ];
    const rehabOptions = [
        { value: '0', label: 'Basic' },
        { value: '1', label: 'Light' },
        { value: '2', label: 'Medium' },
        { value: '3', label: 'Heavy' },
        { value: '4', label: 'Full Gut' }
    ];
    const searchPercentageOptions = [
        { value: '10', label: '10' },
        { value: '15', label: '15' },
        { value: '20', label: '20' },
        { value: '25', label: '25' },
        { value: '30', label: '30' },
        { value: '35', label: '35' },
        { value: '40', label: '40' },
        { value: '45', label: '45' },
        { value: '50', label: '50' },
        { value: '55', label: '55' },
        { value: '60', label: '60' }
    ];
    const soldwithindays = [
        { value: '&sold_within_days=7', label: 'last 1 week' },
        { value: '&sold_within_days=30', label: 'last 1 month' },
        { value: '&sold_within_days=90', label: 'last 3 month' },
        { value: '&sold_within_days=180', label: 'last 6 months' },
        { value:  '&sold_within_days=365', label: 'last 1 year' },
        { value: '&sold_within_days=730', label: 'last 2 years' },
        { value: '&sold_within_days=1095', label: 'last 3 years' },
        { value: '&sold_within_days=1825', label: 'last 5 years' },
    ];

    const useDaysOnMarketOptions = [
        { value: 'NA', label: 'No' },
        { value: 'in_range', label: 'Yes' }
    ];
    const stateOptions = states?.map(state => ({
        value: state,
        label: state,
      }));
    
    const citiesOptions = cities?.map(city => ({
        value: city,
        label: city,
    }));
    const countiesOptions = counties?.map(county => ({
        value: county,
        label: county,
    }));
    const soldwithdays = counties?.map(county => ({
        value: county,
        label: county,
    }));

    
    
    return (
        <div className="filter">
            <div className="justify-start items-start gap-8 inline-flex mb-8">
                <div className="w-56 text-slate-950 font-medium">Home Filters</div>
                <div className="w-56 text-slate-950 font-medium">Analysis filters</div>
            </div>
            <div className="flex justify-between items-start mb-6">
                <div className="max-h-[70vh] overflow-y-auto overflow-x-hidden scroll-none px-1">
                    <Select
                        label="State"
                        options={props.isdemo ? [] : stateOptions}
                        value={localFilterData?.state}
                        onChange={(e) => updateFilter('state', e.target.value)}
                    />
                    <Select
                        label="City"
                        options={props.isdemo ? [] : citiesOptions}
                        value={localFilterData?.city}
                        disabled={citydisable}
                        onChange={(e) => updateFilter('city', e.target.value)}
                    />
                     <Select
                        label="County"
                        options={props.isdemo ? [] : countiesOptions}
                        value={localFilterData?.county}
                        disabled={countydisable}
                        onChange={(e) => updateFilter('county', e.target.value)}
                    />
                    <Slider min="0" max="5000000" steps="1" dollar="$" label="Min Price" value={localFilterData?.min_price_c}
                        onChange={(e) => updateFilter('min_price_c', e.target.value)} />
                    <Slider min="0" max="5000000" steps="1" dollar="$" label="Max Price" value={localFilterData?.max_price_c}
                        onChange={(e) => updateFilter('max_price_c', e.target.value)} />
                    <Slider min="0" max="8" steps="1" label="Min beds" value={localFilterData?.min_beds_c}
                        onChange={(e) => updateFilter('min_beds_c', e.target.value)} />
                    <Slider min="1" max="8" steps="1" label="Max beds" value={localFilterData?.max_beds_c}
                        onChange={(e) => updateFilter('max_beds_c', e.target.value)} />
                    <Slider min="0" max="8" steps="1" label="Min Baths" value={localFilterData?.min_bath_c} onChange={(e) => updateFilter('min_bath_c', e.target.value)} />
                    
                    <Select
                        label="home type"
                        options={homeTypes}
                        value={localFilterData?.home_types_c}
                        onChange={(e) => updateFilter('home_types_c', e.target.value)}
                    />
                    <Select
                        label="time on market"
                        options={timeOnMarketOptions}
                        value={localFilterData?.time_on_market}
                        onChange={(e) => updateFilter('time_on_market', e.target.value)}
                    />
                    <Slider min="0" max="8000" steps="1" label="Min Square Feet" value={localFilterData?.min_sqft_c}
                        onChange={(e) => updateFilter('min_sqft_c', e.target.value)} />
                    <Slider min="0" max="8000" steps="1" label="Max Square Feet" value={localFilterData?.max_sqft_c}
                        onChange={(e) => updateFilter('max_sqft_c', e.target.value)} />
                    <Slider min="1900" max="2024" steps="1" label="Min Year Built" value={localFilterData?.min_year_b}
                        onChange={(e) => updateFilter('min_year_b', e.target.value)} />
                    <Slider min="1900" max="2024" steps="1" label="Max Year Built" value={localFilterData?.max_year_b}
                        onChange={(e) => updateFilter('max_year_b', e.target.value)} />
                    <Select
                        label="garage spots"
                        options={garages}
                        value={localFilterData?.garage === null ? '' : localFilterData?.garage}
                        onChange={(e) => updateFilter('garage', e.target.value)}
                    />
                    <Select
                        label="pool type"
                        options={poolTypes}
                        value={localFilterData?.pool_types}
                        onChange={(e) => updateFilter('pool_types', e.target.value)}
                    />
                     <Select
                        label="Schools ratings"
                        options={schoolsRating}
                        value={localFilterData?.school_rating_c}
                        onChange={(e) => updateFilter('school_rating_c', e.target.value)}
                    />
                   <Select
                label="schools"
                options={schoolsTypes}
                value={localFilterData?.school_types_c}
                onChange={(e) => updateFilter('school_types_c', e.target.value)}
                disabled={localFilterData?.school_rating_c === null || localFilterData?.school_rating_c === "''" || localFilterData?.school_rating_c === "" ? true : false}
            />
                </div>
                <div className="max-h-[70vh] overflow-y-auto overflow-x-hidden scroll-none px-1">
                    <Select
                        label="Percentage of ARV"
                        options={percentateOfArv}
                        value={localFilterData?.purchase_price_percent_of_arv}
                        onChange={(e) => updateFilter('purchase_price_percent_of_arv', e.target.value)}
                    />
                    <Select
                        label="select radius for comps"
                        options={radiusComp}
                        value={localFilterData?.radius_for_comps}
                        onChange={(e) => updateFilter('radius_for_comps', e.target.value)}
                    />
                    <Select
                        label="square feet limits"
                        options={sqftlimits}
                        value={localFilterData?.sqft_upper_and_lower_limit}
                        onChange={(e) => updateFilter('sqft_upper_and_lower_limit', e.target.value)}
                    />
                    <Select
                        label="rent multiplier"
                        options={rentmultipliers}
                        value={localFilterData?.rent_multiplier}
                        onChange={(e) => updateFilter('rent_multiplier', e.target.value)}
                    />
                    <Select
                        label="annual interest rate"
                        options={annualInvestRate}
                        value={localFilterData?.annual_interest_rate}
                        onChange={(e) => updateFilter('annual_interest_rate', e.target.value)}
                    />
                    <Select
                        label="Loan Term Years"
                        options={loanTermYears}
                        value={localFilterData?.loan_term_years}
                        onChange={(e) => updateFilter('loan_term_years', e.target.value)}

                    />
                    <Select
                        label="% financing"
                        options={financingOptions}
                        value={localFilterData?.percent_financing}
                        onChange={(e) => updateFilter('percent_financing', e.target.value)}

                    />
                     <Select
                        label="purchase cost %"
                        options={purchaseCostOptions}
                        value={localFilterData?.purchase_cost_percentage}
                        onChange={(e) => updateFilter('purchase_cost_percentage', e.target.value)}

                    />
                    <Select
                        label="operating expenses %"
                        options={operatingExpensesOptions}
                        value={localFilterData?.operating_expenses_percentage}
                        onChange={(e) => updateFilter('operating_expenses_percentage', e.target.value)}

                    />
                    <Select
                        label="vacancy cost"
                        options={vacancyCostOptions}
                        value={localFilterData?.vacancy_cost}
                        onChange={(e) => updateFilter('vacancy_cost', e.target.value)}

                    />
                    
                    <Input optionLabel="assignment fee" placeholder="Enter Value..." value={localFilterData?.assignment_fee} onChange={(e) => updateFilter('assignment_fee', e.target.value)} />
                    <Input optionLabel="basic rehab" placeholder="Enter Value..." value={localFilterData?.basic_rehab} onChange={(e) => updateFilter('basic_rehab', e.target.value)} />
                    <Select
                        label="rehab % select"
                        options={rehabOptions}
                        value={localFilterData?.rehab_percentage_select}
                        onChange={(e) => updateFilter('rehab_percentage_select', e.target.value)}
                    />
                     <Select
                        label="% to search for"
                        options={searchPercentageOptions}
                        value={localFilterData?.percentage_we_are_searching_under}
                        onChange={(e) => updateFilter('percentage_we_are_searching_under', e.target.value)}
                    />
                    
                    <Slider
                        min="0"
                        max="5000000"
                        dollar="$"
                        steps="1"
                        label="max purchase price"
                        value={localFilterData?.max_purchase_price}
                        onChange={(e) => updateFilter('max_purchase_price', e.target.value)}
                       
                    />
                    <Select
                        label="use days on market for filter"
                        options={useDaysOnMarketOptions}
                        value={localFilterData?.use_days_market_for_filter}
                        onChange={(e) => updateFilter('use_days_market_for_filter', e.target.value)}

                    />
                    <Slider min="0" max="90" steps="1" label="min days on market range" value={localFilterData?.min_days_market_range} onChange={(e) => updateFilter('min_days_market_range', e.target.value)}   disabled={localFilterData?.use_days_market_for_filter === "NA" }/>
                    <Slider min="7" max="360" steps="1" label="max days on market range" value={localFilterData?.max_days_market_range} onChange={(e) => updateFilter('max_days_market_range', e.target.value)}  disabled={localFilterData?.use_days_market_for_filter === "NA"}/>
                    <Slider min="0" max="5" steps="1" label="minimum number of comps" value={localFilterData?.minimum_number_of_comps} onChange={(e) => updateFilter('minimum_number_of_comps', e.target.value)} />
                    <Select
                        label="sold within dayst"
                        options={soldwithindays}
                        value={localFilterData?.sold_with_days}
                        onChange={(e) => updateFilter('sold_with_days', e.target.value)}

                    />
                    <div className="w-56 text-slate-950 font-medium mb-5">
                        Rehab Cost Table
                    </div>
                    <SecondaryInput min="25" max="25" optionLabel="light rehab" value={localFilterData?.re_li_c} onChange={(e) => updateFilter('re_li_c', e.target.value)} />
                    <SecondaryInput min="25" max="40" optionLabel="medium rehab" value={localFilterData?.re_me_c} onChange={(e) => updateFilter('re_me_c', e.target.value)} />
                    <SecondaryInput min="25" max="55" optionLabel="heavy rehab" value={localFilterData?.re_he_c} onChange={(e) => updateFilter('re_he_c', e.target.value)} />
                    <SecondaryInput min="25" max="75" optionLabel="full gut" value={localFilterData?.re_fu_c} onChange={(e) => updateFilter('re_fu_c', e.target.value)} />
                </div>
            </div>



            <div className="border p-4 bg-white rounded-2xl">
                <div className="w-56 text-slate-950 font-medium">
                    Save Configuration
                </div>
                <div className="text-slate-400 text-sm font-normal">
                    Save Current Configuration as a Buy Box
                </div>
                <div className="flex justify-between items-center mt-6">
                    <div className="w-56 flex-col justify-start items-start gap-4 flex">
                        <input
                            type="text"
                            id = 'buy_box_name'
                            placeholder="Buy Box Name..."
                            value={buyboxname}
                            className="inputField w-64 placeholder:text-slate-400"
                            onChange={handleBuyBoxInput}
                            style={{ background: "white" }}
                        />
                    </div>
                    <PrimaryButton primaryBtn="Save Configuration" onclick={() => saveConfigurations("saveConfigurations")} />
                </div>
            </div>

            <div className="mt-1 flex items-center gap-1">
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                    >
                        <path
                            d="M17.5 5.60839C17.4834 5.60839 17.4584 5.60839 17.4334 5.60839C13.025 5.16673 8.62502 5.00006 4.26669 5.44173L2.56669 5.60839C2.21669 5.64173 1.90835 5.39173 1.87502 5.04173C1.84169 4.69173 2.09169 4.39173 2.43335 4.35839L4.13335 4.19173C8.56669 3.74173 13.0584 3.91673 17.5584 4.35839C17.9 4.39173 18.15 4.70006 18.1167 5.04173C18.0917 5.36673 17.8167 5.60839 17.5 5.60839Z"
                            fill="#EF4444"
                        />
                        <path
                            d="M7.08335 4.76663C7.05002 4.76663 7.01668 4.76663 6.97502 4.75829C6.64168 4.69996 6.40835 4.37496 6.46668 4.04163L6.65002 2.94996C6.78335 2.14996 6.96668 1.04163 8.90835 1.04163H11.0917C13.0417 1.04163 13.225 2.19163 13.35 2.95829L13.5334 4.04163C13.5917 4.38329 13.3584 4.70829 13.025 4.75829C12.6834 4.81663 12.3584 4.58329 12.3084 4.24996L12.125 3.16663C12.0084 2.44163 11.9834 2.29996 11.1 2.29996H8.91668C8.03335 2.29996 8.01669 2.41663 7.89169 3.15829L7.70002 4.24163C7.65002 4.54996 7.38335 4.76663 7.08335 4.76663Z"
                            fill="#EF4444"
                        />
                        <path
                            d="M12.675 18.9584H7.32503C4.41669 18.9584 4.30003 17.3501 4.20836 16.0501L3.66669 7.6584C3.64169 7.31673 3.90836 7.01673 4.25003 6.99173C4.60003 6.97506 4.89169 7.2334 4.91669 7.57506L5.45836 15.9667C5.55003 17.2334 5.58336 17.7084 7.32503 17.7084H12.675C14.425 17.7084 14.4584 17.2334 14.5417 15.9667L15.0834 7.57506C15.1084 7.2334 15.4084 6.97506 15.75 6.99173C16.0917 7.01673 16.3584 7.3084 16.3334 7.6584L15.7917 16.0501C15.7 17.3501 15.5834 18.9584 12.675 18.9584Z"
                            fill="#EF4444"
                        />
                        <path
                            d="M11.3833 14.375H8.60831C8.26664 14.375 7.98331 14.0917 7.98331 13.75C7.98331 13.4083 8.26664 13.125 8.60831 13.125H11.3833C11.725 13.125 12.0083 13.4083 12.0083 13.75C12.0083 14.0917 11.725 14.375 11.3833 14.375Z"
                            fill="#EF4444"
                        />
                        <path
                            d="M12.0833 11.0416H7.91666C7.57499 11.0416 7.29166 10.7583 7.29166 10.4166C7.29166 10.075 7.57499 9.79163 7.91666 9.79163H12.0833C12.425 9.79163 12.7083 10.075 12.7083 10.4166C12.7083 10.7583 12.425 11.0416 12.0833 11.0416Z"
                            fill="#EF4444"
                        />
                    </svg>
                </div>

                {(props.buy_box_name && props.buy_box_name.toLowerCase() !== 'default') &&
                    <div className="text-red-500 font-medium text-sm uppercase mt-1 cursor-pointer" onClick={handleDelete}>
                        Delete Current Buy Box
                    </div>
                }
            </div>
        </div>
    );
};

export default Filter;
