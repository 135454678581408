import React from "react";
import dotUi from "../../dist/images/Dot_Grid.svg";
import google from "../../dist/images/Google.svg";
import PrimaryButton from "../constants/buttons/PrimaryButton";
import { useState,useEffect } from 'react';
import SignInput from "../constants/buttons/SignInput";
import TopBar from "../constants/TopBar";
import { SERVER_URL } from "../../config";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { createBrowserHistory } from 'history';

const SignUp = () => {

  const [ user, setUser ] = useState([]);
  const history = createBrowserHistory();
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
});
  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };
  useEffect(() => {
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    return axios.post(`${SERVER_URL}api/accounts/google/siginsignup/`, res.data, {
                    headers: {
                      Accept: 'application/json'
                            }
                        });
                    })
                    .then(response => {
                      localStorage.setItem('accessToken', response.data.access);
                      history.push('/app_demo');
                      window.dispatchEvent(new Event('popstate')); 
                      window.location.reload();
                    })
                .catch((err) => console.log(err));
        }
    },[user]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirm_password: '',
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setError('')
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    if (!validatePassword(formData.password)) {
      setError(
        'Password must include at least one uppercase letter, one lowercase letter, one number, and one special character.'
      );
      return;
    }

    try {
      const response = await fetch(`${SERVER_URL}api/accounts/signup/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: formData.name,
          email: formData.email,
          password: formData.password,
        }),
      });

      if (response.status === 201) {
        history.push('/sign_in');
        window.dispatchEvent(new Event('popstate'));

      } else {
        const data = await response.json();
        if (data.error.includes('UNIQUE constraint failed: accounts_customuser.email')) {

          setError('Email already exists.');

        } else if (data.error.includes('UNIQUE constraint failed: accounts_customuser.username')) {

          setError('Username already exists.');

        } else {
          setError(data.error);
        }
      }
    } catch (error) {
      console.error('An unexpected error occurred', error);
    }
  };

  return (
    <div className="px-5 py-7 sm:ml-64">
      <section className="mb-7">
        <TopBar heading="New User" title="Create Account" />
      </section>
      <section className="sign">
        <div className="inner-box absolute">
          <img
            className="dotui-1 absolute bottom-0 right-0"
            src={dotUi}
            alt=""
          />
          <img className="dotui-2" src={dotUi} alt="" />
          <div className="sign-form absolute top-16 left-16">
            <div className="Card w-96 p-6 bg-white rounded-2xl flex-col justify-start items-start gap-8 inline-flex">
              <div className="Text self-stretch flex-col justify-start items-start gap-2 flex">
                <div className="SignIn self-stretch text-slate-950 text-base font-['SF Pro']">
                  Create Account
                </div>
                <div className="self-stretch text-slate-400 text-sm font-normal font-['SF Pro'] leading-7">
                  {error && (
                    <div className="error-message text-red-500">
                      {error}
                    </div>
                    )}
                </div>
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                <SignInput type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} />
                <SignInput
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleChange}
                />
                <SignInput
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <SignInput
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm Password"
                  value={formData.confirm_password}
                  onChange={handleChange}
                />
              </div>
              <div className="Row self-stretch justify-start items-center gap-6 inline-flex">
              <button onClick={handleSubmit}>
                  <PrimaryButton primaryBtn="Continue" />
                </button>
                <div onClick={() => login()} className="grow shrink basis-0 h-6 justify-end items-center gap-2 flex cursor-pointer">
                  <img src={google} alt="" />
                  <div className="text-center text-slate-950 text-sm">
                    Continue Using Google
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignUp;
