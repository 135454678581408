import React from "react";
import PrimaryButton from "../../constants/buttons/PrimaryButton";
import { Link } from "react-router-dom";
import TopBar from "../../constants/TopBar";
import { API_URL, DOMAIN_URL, SERVER_URL } from "../../../config";
import { Button } from "flowbite-react";
import { useEffect, useState } from "react";
import { isTokenExpired } from '../../authutils';
import { createBrowserHistory } from 'history';


const Purchase = ({ product_amount, product_id }) => {
  const history = createBrowserHistory();
  useEffect(() => {
    const isUserLogin = isTokenExpired();

    if (isUserLogin) {
      history.push("/sign_in");
      window.dispatchEvent(new Event("popstate"));
    }
    const checkShowPricingButton = async () => {
      try {
        // Replace with your actual authentication token
        const response = await fetch(`${SERVER_URL}api/payments/check_payment`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            'Content-Type': 'application/json', // Adjust content type as needed
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data) {
          history.push("/sign_in");
          window.dispatchEvent(new Event("popstate"));
        }; // Assuming your API returns a boolean value
      } catch (error) {
        console.error('Error checking showPricingButton:', error);
      }
    };
    checkShowPricingButton();
  }, []);
  useEffect(() => {

    const isUserLogin = isTokenExpired();
    if (isUserLogin) {
      history.push("/sign_in");
      window.dispatchEvent(new Event("popstate"));
    }
  }, []);

  const [paymentUrl, setPaymentUrl] = useState(null);
  const handlePayment = async () => {

    try {
      // Replace with your API endpoint
      const apiUrl = `${API_URL}api/payments/generate_url/`;

      // Data to be sent in the POST request
      const postData = {
        success: `${DOMAIN_URL}pricing/payment_history`,
        cancel: `${DOMAIN_URL}pricing/payment`,
        product_id: "price_1ONvHMIfYjYcC2fuGNhuDfQm"
      };

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        throw new Error('Failed to initiate payment');

      }
      const data = await response.json();
      setPaymentUrl(data.payment_link);

      // Redirect to the payment URL
      window.location.href = data.payment_link
    } catch (error) {
      console.error('Error initiating payment:', error.message);
    }
  };
  return (
    <div className="px-2 md:px-5 py-7 sm:ml-64">
      <section className="mb-7">
        <TopBar heading="Pricing" title="Purchase" />
      </section>
      <section className="plans">
        <div className="plans-box">
          <div className="Card shadow-lg md:w-96 px-6 py-10 bg-white rounded-2xl flex-col justify-start items-start gap-8 inline-flex">
            <div className="">
              <div className="self-stretch text-slate-950 text-base font-['SF Pro']">
                Selected Plan:
              </div>
              <div className="self-stretch text-slate-950 text-2xl font-['SF Pro']">
                Standard Plan
              </div>
              <div className="self-stretch text-slate-950 text-base font-['SF Pro']">
                ${product_amount}/mo
              </div>
              <div className="mt-6">
                <div className="self-stretch text-slate-950 text-lg font-['SF Pro']">
                  Includes:
                </div>
                <div className="self-stretch leading-6 text-slate-400 text-sm font-normal font-['SF Pro'] leading-7">
                  Access to the Birdog application. <br />
                  Unlimited Cities. (all that are in the city dropdown) <br />
                  Unlimited Buy Boxes.
                </div>
                <div className="self-stretch text-slate-400 text-sm font-normal font-['SF Pro'] leading-7">
                </div>
                <div className="self-stretch text-slate-400 text-sm font-normal font-['SF Pro'] leading-7">
                </div>
              </div>
            </div>
            <div>
              <Button onClick={handlePayment}>
                <PrimaryButton primaryBtn="Continue with Standard Plan" />
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Purchase;