import React from "react";
import SearchBox from "../constants/SearchBox";
import Filter from "../constants/Filter";
import Table from "../constants/Table";
import PrimaryButton from "../constants/buttons/PrimaryButton";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../authutils";
import { API_URL,SERVER_URL } from "../../config";
import { Link } from "react-router-dom";
import { createBrowserHistory } from 'history';


const Demo = () => {
  const [filterData, setFilterData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [docx_file, setDocsFileurl] = useState("");
  const [csv_file, setCsvFileurl] = useState("");
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [btn_text, setBtnText] = useState('Search');
  const [is_disable_btn, setBtnDisable] = useState(false);
  const [taskID, setTaskID] = useState('');
  const [taskStatus, setTaskStatus] = useState('');
  const [pauseSubsStatus, setPauseSubsStatus] = useState(false); 
  const [current_box_name, setBuyoxName] = useState('default'); 
  const history = createBrowserHistory();
  useEffect(() => {
    const isUserLogin = isTokenExpired();

    if (isUserLogin) {
      history.push("/sign_in");
      window.dispatchEvent(new Event("popstate"));
    }
    }, []);
    const handleFilterChange = (filterData) => {
      setFilterData(filterData);
      setBuyoxName(filterData?.name);
    };
    const handleBuyBoxChange = (filterData) => {
      setFilterData(filterData);
    };

   
    const fetchTaskStatus = (task_status) => {
      if (taskID !== "" && taskStatus !== "SUCCESS"){
      
      fetch(`${SERVER_URL}api/script/check/status/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ task_id: taskID }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setTaskStatus(data["status"]);
          if (data.status === "SUCCESS") {

            handleResults(data);
            setCsvFileurl(data["files_path"][0]);
            setDocsFileurl(data["files_path"][1]);
            setBtnText("Search");
            setBtnDisable(false);
            setMessage("");
          }
        })
        .catch((error) => {
          setMessage("")
          setError(`Error fetching results: ${error}`);
        });
      }
    };
    useEffect(() => {
      const intervalId = setInterval(() => {
        if (taskStatus !== "SUCCESS") {
          fetchTaskStatus(taskStatus);
        }
      }, 10000); 
    
      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [taskID, taskStatus]);
  const handleResults = (filterData) => {
    setError(''); 
    fetch(`${SERVER_URL}api/script/get_script_data/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify(filterData),
    })
      .then((response) => {
        if (!response.ok) {
         
            throw new Error(`HTTP error! Status: ${response.status}`);
          
        }
        return response.json();
      })
      .then((data) => {
          if (data.error){
            setMessage("")
            setError(data.error); // Assuming the error message is available as 'error' in the JSON response
          }else{
            setTableData(data);

          }
      })
      .catch(error => {
        console.error('Error processing file:', error);
        setMessage("")
        setError("Error processing file");
      });
  };

  const handleSearch = () => {
    setError('');
    document.getElementById("spinner").style.display = "grid";
    document.getElementById("grid").style.filter = "blur(20px)";
    setTaskStatus("");
    
    fetch(`${SERVER_URL}api/script/run_script/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify(filterData),
    })
      .then((response) => response.json())
      .then((data) => {
        document.getElementById("spinner").style.display = "none";
        document.getElementById("grid").style.filter = "blur(0px)";
        setMessage(data.message)
        setTaskID(data.task_id)
        setBtnText("Searching....")
        setBtnDisable(true);

      })
      .catch((error) => {
        document.getElementById("spinner").style.display = "none";
        document.getElementById("grid").style.filter = "blur(0px)";
        console.error("Error:", error);
        setMessage("")
        setError('No Record Found!.');
      });
  };

  const downloadFile = (fileUrl, type) => {
    fetch(`${SERVER_URL}api/script/download_file/`+type, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`, // if using token authentication
        // other headers if necessary
      },

    })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const contentDisposition = response.headers.get('Content-Disposition');
      let fileName = 'download';
  
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch && fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
          const startIndex = fileName.indexOf('generated_csv') + 'generated_csv'.length + 1;
          fileName = fileName.substring(startIndex);
          fileName = fileName.replace(/[^a-zA-Z0-9.]+$/, '');
        }
      }
  
      return response.blob().then(blob => ({ blob, fileName }));
    })
    .then(({ blob, fileName }) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    })
    .catch((error) => console.error("Error downloading file:", error));
  };
  const handleBuyBoxSelect =(data)=>{
    setFilterData(data);
  }
  const handleDelete = ()=>{
    setBuyoxName('default')
}
const handleSaveConfig = (value)=>{
  setBuyoxName(value);
}
const handleBoxValueChange = (value)=>{

  setBuyoxName(value);
}
  return (
    <>
      <div className={!pauseSubsStatus ? "blur-screen" : ""}>
        <div className="px-4 sm:ml-64" style={{ display: "flex", flexDirection:'column', justifyContent: "center", alignItems: "center" }}>
          {error && (
            <div style={{ color: 'red', textAlign: 'center', width: '100%' }}>
              {error}
              
            </div>
          )}
          {message && (
            <div style={{ color: 'green', textAlign: 'center', width: '100%' }}>
              {message}
              
            </div>
          )}
          <div className="spinner" id="spinner"></div>
          <div className="grid grid-cols-5 mb-7" id="grid">
            <div className="col-span-5 py-8">
             <SearchBox is_btn_disabled={is_disable_btn} btn_text={btn_text} handleSelectChange={handleBoxValueChange} box_name={current_box_name} demo={true} onBuyBox={handleBuyBoxSelect} onSearch={handleSearch} />
            </div>
            <div className="col-span-5 md:flex">
              <div className="md:mr-5">
              <Filter isdemo={true} onSaveConfig={handleSaveConfig} buy_box_name={current_box_name} onDelete={handleDelete} onFilterChange={(localFilterData)=>handleFilterChange(localFilterData)} onDataBuyBoxChange={()=>handleBuyBoxChange} dataOfBuyBox={filterData} />
              </div>
              <div className="bg-white overflow-x-auto p-5 mb-2 flex flex-col rounded-2xl">
                <div className="bg-white overflow-x-auto ">
                  <Table tableData={tableData} docx_file={docx_file} csv_file={csv_file} />
                </div>
                <div className="flex flex-wrap justify-end items-center mt-5">
                  <div className="flex items-center mt-4 sm:mt-0 gap-3">
                    <PrimaryButton onclick={() => downloadFile(csv_file, 'csv')} primaryBtn="Download CSV" />
                    <PrimaryButton onclick={() => downloadFile(docx_file, 'docx')} primaryBtn="Download Word" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </>
  );
};

export default Demo;
