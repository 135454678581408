import React, { useState } from "react";
import TopBar from "../constants/TopBar";
import SecondaryBtn from "../constants/buttons/SecondaryBtn";
import PrimaryButton from "../constants/buttons/PrimaryButton";
import { useEffect } from "react";
import { isTokenExpired } from "../authutils";
import { Link } from "react-router-dom";
import {  SERVER_URL} from "../../config";
import { createBrowserHistory } from 'history';
import { useLocation } from "react-router-dom";

const MyAccount = () => {
  const [username, setUsername] = useState("");
  const history = createBrowserHistory();
  const location = useLocation();
  const SiginoutUser = () =>{
    localStorage.removeItem('accessToken');
    window.location.reload();
  }
  const DeleteUser = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
  
      if (!accessToken) {
        // Handle the case where the access token is not available
        console.error("Access token not found.");
        return;
      }
  
      const response = await fetch(`${SERVER_URL}api/accounts/delete/user/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      localStorage.removeItem("accessToken");
      window.location.reload();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };
  useEffect(() => {
    const isUserLogin = isTokenExpired();
    console.log(isUserLogin);
    const fetchData = async () => {
      try {
        if (!isUserLogin) {
          const accessToken = localStorage.getItem("accessToken");
          // Replace with your actual API endpoint
          const response = await fetch(`${SERVER_URL}api/accounts/get/user/`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },

          });

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const data = await response.json();

          setUsername(data.user.username)
        }
      } catch (error) {
        console.error('Error making API request:', error);
      }
    };

    fetchData();

    if (isUserLogin) {
      history.push("/sign_in");
      window.dispatchEvent(new Event("popstate"));
    }
    }, [isTokenExpired]);
  return (
    <div className="px-5 py-7 sm:ml-64">
      <TopBar heading="Connected Apps" title="My Account" />
      <div class="w-64 h-14 p-2 bg-white rounded-3xl justify-start items-center gap-4 inline-flex cursor-pointer" style={{padding:'1rem', marginTop:'1rem'}}>

      <h1>Hello {username? username:"....."}</h1>
      </div>
      <div className="mt-7">
        <SecondaryBtn fw="ba" name="BirDogg App Demo" />
      </div>
      <div className="mt-7">
        <a href="/pricing/payment_history">
        <SecondaryBtn fw="ba" name="Payment History" />
        </a>
      </div>
      <div className="mt-7">

          <SecondaryBtn onclick={DeleteUser} style={{backgroundColor: 'rgba(255, 0, 0, 0.5)'}} fw="ba" name="Delete Account" />

        <p style={{color:'red', fontSize:'14px'}}>Clicking the 'Delete Account' button will permanently remove your account and cancel any active subscriptions.</p>
      </div>
      <div className="mt-7" style={{display:'flex'}}>
        <Link to="/sign_in">
          <PrimaryButton onclick={SiginoutUser} fw="ba" primaryBtn="Sign Out" />
        </Link>
      </div>
    </div>
  );
};

export default MyAccount;
