import React, { useState } from "react";

export default function Slider(props) {
  const [sliderValue, setSliderValue] = useState(props.value);
  const [isTextInputActive, setIsTextInputActive] = useState(false);
  const textinput = () =>{
    console.log("hreer");
  }
  const handleChange = (e) => {
    setSliderValue(e.target.value);
    props.onChange(e);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSliderValue(inputValue);
    props.onChange({ target: { value: inputValue } });
  };

  const formattedSliderValue =
    props.min === 2000 ? props.value : new Intl.NumberFormat().format(props.value);

  return (
    <div className="w-56 h-20 flex-col justify-start items-start gap-4 flex mb-8">
      <div className="City self-stretch text-slate-400 text-xs uppercase">
        {props.label}
      </div>
      <div
        className={`bg-slate-50 border border-slate-200 justify-start items-center w-56 inline-flex ${
          isTextInputActive ? "border-blue-500" : ""
        }`}
        style={{ padding: "16px 20px", borderRadius: "32px", height: "55px" }}
      >
        <div className="justify-start items-center flex">
          {isTextInputActive ? (
            <input
              type="number"
              className="w-full"
              value={sliderValue}
              onChange={handleInputChange}
              onBlur={() => setIsTextInputActive(false)}
              min={props.min}
              max={props.max}
              disabled={props.disabled}
            />
          ) : (
            <input
              type="range"
              id={props.id}
              className="custom-range-slider w-full"
              min={props.min}
              max={props.max}
              step={props.steps}
              value={props.value}
              onChange={handleChange}
              onClick={() => setIsTextInputActive(true)}
              disabled={props.disabled}
            />
          )}
        </div>
        <div className="0 w-12 text-right text-slate-950 text-sm font-normal font-['SF Pro']">
          {props.dollar}
          {formattedSliderValue}
        </div>
      </div>
    </div>
  );
}
