import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { isTokenExpired } from "../authutils";
import { API_URL, SERVER_URL } from "../../config";
const Sidebar = () => {
  const currentLocaion = useLocation();
  const[isUserLoginstate, setIsUserLogin] = useState(false);
  const [showPricingButton, setShowPricingButton] = useState(false);

  const handleRouteChange = () => {
    // Perform actions when the route changes
    const isLogin = isTokenExpired();
    setIsUserLogin(isLogin);
  };
  const checkShowPricingButton = async () => {
    if (!isTokenExpired()) {
    try {
        const response = await fetch(`${SERVER_URL}api/payments/check_payment`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            'Content-Type': 'application/json', // Adjust content type as needed
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        setShowPricingButton(data); // Assuming your API returns a boolean value
      } catch (error) {
        console.error('Error checking showPricingButton:', error);
      }
    }
  };
  useEffect(() => {
    // Initial check
    handleRouteChange();
    checkShowPricingButton();

    // Add a listener for route changes
    const removeListener = () => {
      window.removeEventListener('popstate', handleRouteChange);
      window.removeEventListener('hashchange', handleRouteChange);
    };

    window.addEventListener('popstate', handleRouteChange);
    window.addEventListener('hashchange', handleRouteChange);
    
    const intervalId = setInterval(() => {
      if (!isUserLoginstate){

        checkShowPricingButton();
      }
    }, 2000);
    // Clean up the listener when the component unmounts
    return () => {
      removeListener();
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array means it runs once on mount

  return (
    <>
      <button
        data-drawer-target="logo-sidebar"
        data-drawer-toggle="logo-sidebar"
        aria-controls="logo-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          />
        </svg>
      </button>
      <aside
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
        aria-label="Sidebar"
      >
        <div className="h-full px-5 py-4 overflow-y-auto sidebar">
          <Link to="/" className="flex items-center mb-5 mt-2 brand">
            <span>Bir</span>
            <span style={{ color: "white" }}>Dog</span>
          </Link>
          <ul className="space-y-2">
            <span className="h6">Main Menu</span>
            <li>
              <Link
                to="/"
                className={`btn ${
                  currentLocaion.pathname === "/" ? "active" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <path d="M14.6168 7.55001C13.1418 7.55001 11.9418 6.35001 11.9418 4.87501C11.9418 3.40001 13.1418 2.20001 14.6168 2.20001C16.0918 2.20001 17.2918 3.40001 17.2918 4.87501C17.2918 6.35001 16.0918 7.55001 14.6168 7.55001ZM14.6168 3.44168C13.8334 3.44168 13.1918 4.08335 13.1918 4.86668C13.1918 5.65001 13.8334 6.29168 14.6168 6.29168C15.4001 6.29168 16.0418 5.65001 16.0418 4.86668C16.0418 4.08335 15.4001 3.44168 14.6168 3.44168Z" />
                  <path d="M5.38338 7.55001C3.90838 7.55001 2.70837 6.35001 2.70837 4.87501C2.70837 3.40001 3.90838 2.20001 5.38338 2.20001C6.85838 2.20001 8.05837 3.40001 8.05837 4.87501C8.05837 6.35001 6.85838 7.55001 5.38338 7.55001ZM5.38338 3.44168C4.60005 3.44168 3.95837 4.08335 3.95837 4.86668C3.95837 5.65001 4.60005 6.29168 5.38338 6.29168C6.16671 6.29168 6.80837 5.65001 6.80837 4.86668C6.80837 4.08335 6.17505 3.44168 5.38338 3.44168Z" />
                  <path d="M14.6168 17.8083C13.1418 17.8083 11.9418 16.6083 11.9418 15.1333C11.9418 13.6583 13.1418 12.4583 14.6168 12.4583C16.0918 12.4583 17.2918 13.6583 17.2918 15.1333C17.2918 16.6083 16.0918 17.8083 14.6168 17.8083ZM14.6168 13.7C13.8334 13.7 13.1918 14.3417 13.1918 15.125C13.1918 15.9083 13.8334 16.55 14.6168 16.55C15.4001 16.55 16.0418 15.9083 16.0418 15.125C16.0418 14.3417 15.4001 13.7 14.6168 13.7Z" />
                  <path d="M5.38338 17.8083C3.90838 17.8083 2.70837 16.6083 2.70837 15.1333C2.70837 13.6583 3.90838 12.4583 5.38338 12.4583C6.85838 12.4583 8.05837 13.6583 8.05837 15.1333C8.05837 16.6083 6.85838 17.8083 5.38338 17.8083ZM5.38338 13.7C4.60005 13.7 3.95837 14.3417 3.95837 15.125C3.95837 15.9083 4.60005 16.55 5.38338 16.55C6.16671 16.55 6.80837 15.9083 6.80837 15.125C6.80837 14.3417 6.17505 13.7 5.38338 13.7Z" />
                </svg>
                <span className="">App Home</span>
              </Link>
            </li>
            <li>
              <Link
                to="how_it_works"
                className={`btn ${
                  currentLocaion.pathname === "/how_it_works" ? "active" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.99996 18.9583C5.05829 18.9583 1.04163 14.9417 1.04163 9.99999C1.04163 5.05832 5.05829 1.04166 9.99996 1.04166C14.9416 1.04166 18.9583 5.05832 18.9583 9.99999C18.9583 14.9417 14.9416 18.9583 9.99996 18.9583ZM9.99996 2.29166C5.74996 2.29166 2.29163 5.74999 2.29163 9.99999C2.29163 14.25 5.74996 17.7083 9.99996 17.7083C14.25 17.7083 17.7083 14.25 17.7083 9.99999C17.7083 5.74999 14.25 2.29166 9.99996 2.29166Z" />
                  <path d="M10 11.4583C9.65833 11.4583 9.375 11.175 9.375 10.8333V6.66666C9.375 6.32499 9.65833 6.04166 10 6.04166C10.3417 6.04166 10.625 6.32499 10.625 6.66666V10.8333C10.625 11.175 10.3417 11.4583 10 11.4583Z" />
                  <path d="M9.99996 14.1667C9.89163 14.1667 9.78329 14.1417 9.68329 14.1C9.58329 14.0583 9.49163 14 9.40829 13.925C9.33329 13.8417 9.27496 13.7583 9.23329 13.65C9.19163 13.55 9.16663 13.4417 9.16663 13.3333C9.16663 13.225 9.19163 13.1167 9.23329 13.0167C9.27496 12.9167 9.33329 12.825 9.40829 12.7417C9.49163 12.6667 9.58329 12.6083 9.68329 12.5667C9.88329 12.4833 10.1166 12.4833 10.3166 12.5667C10.4166 12.6083 10.5083 12.6667 10.5916 12.7417C10.6666 12.825 10.725 12.9167 10.7666 13.0167C10.8083 13.1167 10.8333 13.225 10.8333 13.3333C10.8333 13.4417 10.8083 13.55 10.7666 13.65C10.725 13.7583 10.6666 13.8417 10.5916 13.925C10.5083 14 10.4166 14.0583 10.3166 14.1C10.2166 14.1417 10.1083 14.1667 9.99996 14.1667Z" />
                </svg>
                <span className="">How It Works</span>
              </Link>
            </li>
            {isUserLoginstate ? (
              <>
            <li>
              <Link
                to="sign_in"
                className={`btn ${
                  currentLocaion.pathname === "/sign_in" ? "active" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <path d="M14.5333 12.8083C14.375 12.8083 14.2167 12.75 14.0917 12.625C13.85 12.3833 13.85 11.9833 14.0917 11.7417L15.7833 10.05L14.0917 8.35833C13.85 8.11666 13.85 7.71666 14.0917 7.475C14.3333 7.23333 14.7333 7.23333 14.975 7.475L17.1083 9.60833C17.35 9.85 17.35 10.25 17.1083 10.4917L14.975 12.625C14.85 12.75 14.6917 12.8083 14.5333 12.8083Z" />
                  <path d="M16.6083 10.675H8.1333C7.79163 10.675 7.5083 10.3917 7.5083 10.05C7.5083 9.70834 7.79163 9.425 8.1333 9.425H16.6083C16.95 9.425 17.2333 9.70834 17.2333 10.05C17.2333 10.3917 16.95 10.675 16.6083 10.675Z" />
                  <path d="M9.79997 17.2917C5.5083 17.2917 2.5083 14.2917 2.5083 9.99999C2.5083 5.70833 5.5083 2.70833 9.79997 2.70833C10.1416 2.70833 10.425 2.99166 10.425 3.33333C10.425 3.67499 10.1416 3.95833 9.79997 3.95833C6.24163 3.95833 3.7583 6.44166 3.7583 9.99999C3.7583 13.5583 6.24163 16.0417 9.79997 16.0417C10.1416 16.0417 10.425 16.325 10.425 16.6667C10.425 17.0083 10.1416 17.2917 9.79997 17.2917Z" />
                </svg>
                <span className="">Sign In</span>
              </Link>
            </li>
            <li>
              <Link
                to="create_account"
                className={`btn ${
                  currentLocaion.pathname === "/create_account" ? "active" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <path d="M15 10.625H5C4.65833 10.625 4.375 10.3417 4.375 10C4.375 9.65833 4.65833 9.375 5 9.375H15C15.3417 9.375 15.625 9.65833 15.625 10C15.625 10.3417 15.3417 10.625 15 10.625Z" />
                  <path d="M10 15.625C9.65833 15.625 9.375 15.3417 9.375 15V5C9.375 4.65833 9.65833 4.375 10 4.375C10.3417 4.375 10.625 4.65833 10.625 5V15C10.625 15.3417 10.3417 15.625 10 15.625Z" />
                </svg>
                <span className="">Create Account</span>
              </Link>
            </li>
            </>
              ): null}
            <li>
              <Link
                to="app_demo"
                className={`btn ${
                  currentLocaion.pathname === "/app_demo" ? "active" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <path d="M12.5 18.9583H7.50004C3.82504 18.9583 2.70837 17.8417 2.70837 14.1667V5.83333C2.70837 2.15833 3.82504 1.04166 7.50004 1.04166H12.5C16.175 1.04166 17.2917 2.15833 17.2917 5.83333V14.1667C17.2917 17.8417 16.175 18.9583 12.5 18.9583ZM7.50004 2.29166C4.51671 2.29166 3.95837 2.85833 3.95837 5.83333V14.1667C3.95837 17.1417 4.51671 17.7083 7.50004 17.7083H12.5C15.4834 17.7083 16.0417 17.1417 16.0417 14.1667V5.83333C16.0417 2.85833 15.4834 2.29166 12.5 2.29166H7.50004Z" />
                  <path d="M11.6667 5.20834H8.33337C7.99171 5.20834 7.70837 4.925 7.70837 4.58334C7.70837 4.24167 7.99171 3.95834 8.33337 3.95834H11.6667C12.0084 3.95834 12.2917 4.24167 12.2917 4.58334C12.2917 4.925 12.0084 5.20834 11.6667 5.20834Z" />
                  <path d="M10 16.55C8.94171 16.55 8.08337 15.6917 8.08337 14.6333C8.08337 13.575 8.94171 12.7167 10 12.7167C11.0584 12.7167 11.9167 13.575 11.9167 14.6333C11.9167 15.6917 11.0584 16.55 10 16.55ZM10 13.9583C9.63337 13.9583 9.33337 14.2583 9.33337 14.625C9.33337 14.9917 9.63337 15.2917 10 15.2917C10.3667 15.2917 10.6667 14.9917 10.6667 14.625C10.6667 14.2583 10.3667 13.9583 10 13.9583Z" />
                </svg>
                <span className="">App Demo</span>
              </Link>
            </li>
            {!isUserLoginstate ? (
                 <>
            <li>
              <Link
                to="pricing"
                className={`btn ${currentLocaion.pathname === "/pricing" || currentLocaion.pathname === "/pricing/payment" || currentLocaion.pathname === "/pricing/payment_history" ? "active" : ""
                  }`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><path d="M312 24V34.5c6.4 1.2 12.6 2.7 18.2 4.2c12.8 3.4 20.4 16.6 17 29.4s-16.6 20.4-29.4 17c-10.9-2.9-21.1-4.9-30.2-5c-7.3-.1-14.7 1.7-19.4 4.4c-2.1 1.3-3.1 2.4-3.5 3c-.3 .5-.7 1.2-.7 2.8c0 .3 0 .5 0 .6c.2 .2 .9 1.2 3.3 2.6c5.8 3.5 14.4 6.2 27.4 10.1l.9 .3c11.1 3.3 25.9 7.8 37.9 15.3c13.7 8.6 26.1 22.9 26.4 44.9c.3 22.5-11.4 38.9-26.7 48.5c-6.7 4.1-13.9 7-21.3 8.8V232c0 13.3-10.7 24-24 24s-24-10.7-24-24V220.6c-9.5-2.3-18.2-5.3-25.6-7.8c-2.1-.7-4.1-1.4-6-2c-12.6-4.2-19.4-17.8-15.2-30.4s17.8-19.4 30.4-15.2c2.6 .9 5 1.7 7.3 2.5c13.6 4.6 23.4 7.9 33.9 8.3c8 .3 15.1-1.6 19.2-4.1c1.9-1.2 2.8-2.2 3.2-2.9c.4-.6 .9-1.8 .8-4.1l0-.2c0-1 0-2.1-4-4.6c-5.7-3.6-14.3-6.4-27.1-10.3l-1.9-.6c-10.8-3.2-25-7.5-36.4-14.4c-13.5-8.1-26.5-22-26.6-44.1c-.1-22.9 12.9-38.6 27.7-47.4c6.4-3.8 13.3-6.4 20.2-8.2V24c0-13.3 10.7-24 24-24s24 10.7 24 24zM568.2 336.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 485.5c-23.4 17.2-51.6 26.5-80.7 26.5H192 32c-17.7 0-32-14.3-32-32V416c0-17.7 14.3-32 32-32H68.8l44.9-36c22.7-18.2 50.9-28 80-28H272h16 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H288 272c-8.8 0-16 7.2-16 16s7.2 16 16 16H392.6l119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384l0 0-.9 0c.3 0 .6 0 .9 0z" /></svg>
                <span className="">Pricing</span>
              </Link>
            </li>
                 
                      <li>
                        <span className="h6 space-y-2">Accounts</span>
                      </li>
                      <li>
                        <Link
                          to="my_account"
                          className={`btn ${
                            currentLocaion.pathname === "/my_account" ? "active" : ""
                          }`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                          >
                            {/* SVG path for My Account */}
                          </svg>
                          <span className="">My Account</span>
                        </Link>
                      </li>
                      <li>
                        {showPricingButton &&(
                        <Link
                          to="/birdog"
                          className={`btn flex-1 ${
                            currentLocaion.pathname === "/birdog" ? "active" : ""
                          }`}
                          style={{
                            border: "1px solid #1E293B",
                            borderRadius: "32px",
                            color: "white",
                            fill: "white",
                          }}
                        >
                          <span className="">BirDog</span>
                          <span className="flex-1 whitespace-nowrap"></span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                          >
                            {/* SVG path for BirDog */}
                          </svg>
                        </Link>
                        )}
                      </li>
                      <li>
                      {showPricingButton && (
                        <Link
                          to="/flexmls"
                          className={`btn flex-1 ${currentLocaion.pathname === "/flexmls" ? "active" : ""}`}
                          style={{
                            border: "1px solid #1E293B",
                            borderRadius: "32px",
                            color: "white",
                            fill: "white",
                          }}
                        >
                          <span className="">Flexmls</span>
                          <span className="flex-1 whitespace-nowrap"></span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                          >
                            {/* SVG path for Flexmls */}
                          </svg>
                        </Link>
                      )}
                    </li>
                    </>
              ) : null}

          </ul>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
