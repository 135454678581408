import React from "react";
import { Link } from "react-router-dom";
import TopBar from "../../constants/TopBar";
import { useEffect, useState } from "react";
import { isTokenExpired } from '../../authutils';
import { API_URL, DOMAIN_URL, SERVER_URL } from "../../../config";


const PaymentHistory = () => {
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [message, setMesaage] = useState("");
    const [successmessage, setsuccessMesaage] = useState("");
    const [payment_object, setPaymentObject] = useState(null);
    const cancelSubscription = async () => {
        try {
            const response = await fetch(`${SERVER_URL}api/payments/cancel/subscription/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },

            });

            if (!response.ok) {
                const data = await response.json();
                setMesaage(data.message);
                console.log(data.error);

            }

            const data = await response.json();
            console.log('Response data:', data);
            setMesaage("");
            setsuccessMesaage(data.message);
            getSubscription();
        } catch (error) {
            console.log(error);
        }
    };

    const getSubscription = async () => {
        try {
            const response = await fetch(`${SERVER_URL}api/payments/get/payment/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },

            });

            if (!response.ok) {
                const data = await response.json();

                console.log(data);
            }

            const data = await response.json();
            setPaymentObject(data.data);
            console.log('Response data:', data);
            //   setMesaage("");
            //   setsuccessMesaage(data.message);
        } catch (error) {
            console.error('Error:', error.message);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Replace with your actual user token

                const response = await fetch(`${SERVER_URL}api/payments/payment_history/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
                        'Content-Type': 'application/json',
                        // Add any other headers if needed
                    },
                });

                const data = await response.json();
                setPaymentHistory(data);
            } catch (error) {
                console.error('Error fetching payment history:', error);
            }
        };

        const isUserLogin = isTokenExpired();
        if (isUserLogin) {
            // Redirect or handle the case when the user is not logged in
        } else {

            setTimeout(() => {
                // Now call fetchData
                fetchData();
            }, 2000);
        }
        getSubscription();
    }, []);
    return (
        <div className="px-2 md:px-5 py-7 sm:ml-64">
            <section className="mb-7">
                <TopBar heading="Pricing" title="Purchase History" />
            </section>
            <section className="plans">
                <div className="plans-box">
                    <div className="Card shadow-lg mx-3 md:mx-0 px-6 md:px-6 py-10 bg-white rounded-2xl flex-col justify-start items-start gap-8 inline-flex">
                        <div className="grid grid-cols-3">
                            <div className="col-span-3 md:col-span-1">
                                <div className="self-stretch text-slate-950 text-base font-['SF Pro']">
                                    Selected Plan:
                                </div>
                                <div className="self-stretch text-slate-950 text-2xl font-['SF Pro']">
                                    Standard Plan
                                </div>
                                <div className="self-stretch text-slate-950 text-base font-['SF Pro']">
                                    $99/mo
                                </div>
                                <div className="mt-6">
                                    <div className="self-stretch text-slate-950 text-lg font-['SF Pro']">
                                        Includes:
                                    </div>
                                    <div className="self-stretch leading-6 text-slate-400 text-sm font-normal font-['SF Pro'] leading-7">
                                        Access to the Birdog application. <br />
                                        Unlimited Cities. (all that are in the city dropdown) <br />
                                        Unlimited Buy Boxes.
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3 shadow-sm md:col-span-2 mt-6 md:mt-0 border h-auto md:ms-10 rounded-xl px-5 py-3">
                                <div className="">
                                    <div className="md:flex justify-between" style={{ flexDirection: 'column' }}>
                                        <div className="my-2 md:my-0 md:flex justify-between" style={{ display: 'flex' }}>
                                            <div className="text-slate-950 text-base font-['SF Pro'] mb-2">
                                                Subscription
                                            </div>
                                            <div className="text-slate-950 text-base font-['SF Pro'] mb-2">
                                                Ammount Paid
                                            </div>
                                            <div className="text-slate-950 text-base font-['SF Pro'] mb-2">
                                                Next Bill Due
                                            </div>
                                        </div>
                                        {paymentHistory ? paymentHistory.map((payment, index) => (
                                            <div key={index} className="my-2 md:my-0 md:flex justify-between">
                                                <div className="self-stretch leading-6 text-slate-400 text-sm font-normal font-['SF Pro'] leading-7" style={{ textAlign: 'left', width: 'fit_content' }}>
                                                    Standard Plan
                                                </div>
                                                <div className="leading-6 text-slate-400 text-sm font-normal font-['SF Pro'] leading-7" style={{ textAlign: 'right', width: '80px' }}>
                                                    ${payment.amount}
                                                </div>
                                                <div className="leading-6 text-slate-400 text-sm font-normal font-['SF Pro'] leading-7" style={{ textAlign: 'right', width: '150px' }}>
                                                    {new Date(payment.payment_date).toLocaleString()}
                                                </div>
                                            </div>
                                        )) : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Link>
                                <button type="button" disabled={payment_object?.is_cancel_subscribtion} onClick={() => cancelSubscription()} class="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-3xl text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                                    Cancel Subscription
                                </button>
                            </Link>

                        </div>
                        <p style={{ color: 'red', fontSize: '13px' }}>{message}</p>
                        <p style={{ color: 'green', fontSize: '13px' }}>{successmessage}</p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PaymentHistory