import React from "react";
import dotUi from "../../dist/images/Dot_Grid.svg";
import PrimaryButton from "../constants/buttons/PrimaryButton";
import { useState } from "react";
import SignInput from "../constants/buttons/SignInput";
import TopBar from "../constants/TopBar";
import { SERVER_URL } from "../../config";


const ForgetPassword = () => {

  const [formData, setFormData] = useState({
    email: '',
  });
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setError("")
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSendEmail = async () => {
    document.getElementById("spinner").style.display = "grid";
    document.getElementById("card").style.display = "none"
    document.getElementById("card-parent").style.left = "17rem"
    document.getElementById("card-parent").style.top = "10rem"
    if (formData.email !== ""){

    
    try {
      const response = await fetch(`${SERVER_URL}api/accounts/forgot-password/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email, // Assuming the API uses 'username' for email
        }),
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setMessage(data.message); 
      } else {
        const data = await response.json();
        setError(data.message || 'An unexpected error occurred.'); 
      }
       
    } catch (error) {
      console.error('An unexpected error occurred:', error);
      setError('An unexpected error occurred.');
    }
}else{
    setError("Email is not valid")
}
document.getElementById("spinner").style.display = "none";
document.getElementById("card").style.display = "flex"
document.getElementById("card-parent").style.left = "4rem"
document.getElementById("card-parent").style.top = "3rem"
  };


  return (
    <div className="px-2 md:px-5 py-7 sm:ml-64">
      <section className="mb-7">
        <TopBar title="Forget password" />
      </section>
      <section className="sign">
        <div className="inner-box-2 md:absolute">
          <img
            className="dotui-1 md:absolute bottom-0 right-0 hidden md:block"
            src={dotUi}
            alt=""
          />
          <img className="dotui-2 hidden md:block" src={dotUi} alt="" />
          <div id="card-parent" className="sign-form bg-white rounded-2xl  md:absolute md:top-16 md:left-16" style={{display:'flex', justifyContent:"center", alignItems:"center"}}>
          <div className="spinner" id="spinner"></div>
            <div  id="card" className="Card w-96 p-6 bg-white rounded-2xl flex-col justify-start items-start gap-8 inline-flex">
          
              <div className="Text self-stretch flex-col justify-start items-start gap-2 flex">
                <div className="SignIn self-stretch text-slate-950 text-base font-['SF Pro']">
                  Forget Password
                </div>
               
                <div className="self-stretch text-slate-400 text-sm font-normal font-['SF Pro'] leading-7">
                {message && <div className="text-green-500">{message}</div>}
                {error && <div className="text-red-500">{error}</div>}
                </div>
              </div>
              <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                <SignInput
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <button onClick={handleSendEmail}>
                  <PrimaryButton primaryBtn="Send Email" />
                </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForgetPassword;
