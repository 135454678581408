import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import TopBar from '../../constants/TopBar';
import { useEffect } from "react";
import { isTokenExpired } from '../../authutils';
import { API_URL } from '../../../config';
import {useLocation} from 'react-router-dom';
import { createBrowserHistory } from 'history';


const StripeForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const location = useLocation();
  const history = createBrowserHistory();
  useEffect(() => {
    const isUserLogin = isTokenExpired();
    if (isUserLogin) {
      history.push( "/sign_in");
      window.dispatchEvent(new Event("popstate"));
    }
    else{

    }
    }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {

      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { token, error } = await stripe.createToken(cardElement);

    if (error) {
      setError(error.message);
      setError(error.message);
      setPaymentStatus('fail');
    } else {
      sendTokenToServer(token);
      setPaymentStatus('success');

      history.push( '/pricing/payment_history');
      window.dispatchEvent(new Event('popstate')); 
        window.location.reload();
    }
  };

  const sendTokenToServer = async (token) => {
    try {
      const response = await fetch(`${API_URL}api/payments/charge/`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ token: token.id }), // Send only the token id to the server
      });

      if (response.ok) {
        // Handle success on the client side if needed
      } else {
        const data = await response.json();
        setError(data.message || 'Payment failed');
        // Handle the error on the client side if needed
      }
    } catch (error) {
      console.error('Error sending token to server:', error);
      setError('An unexpected error occurred');
      // Handle the error on the client side if needed
    }
  };

  return (
    <div className="px-2 md:px-5 py-7 sm:ml-64">
      <section className="mb-7">
        <TopBar heading="Stripe Payment" title="Payment" />
      </section>
      <section className="plans">
        <div className="plans-box">

          <form className='Card shadow-lg md:w-96 px-6 py-10 bg-white rounded-2xl flex-col justify-start items-start gap-8 inline-flex' style={{ width: '34rem' }} onSubmit={handleSubmit}>

            <div style={{ marginBottom: '20px', width: '100%' }}>
              <label >
                Credit Card Number
                <CardElement />
              </label>
            </div>
            <button type="submit" className="primary_btn" disabled={!stripe} style={{ padding: '10px', color: 'white', cursor: 'pointer' }}>
              Submit Payment
            </button>

            {paymentStatus === 'success' && (
              <div style={{ marginTop: '10px', color: 'green' }}>Payment successful!</div>
            )}
            {paymentStatus === 'fail' && (
              <div style={{ marginTop: '10px', color: 'red' }}>Payment failed. {error}</div>
            )}
          </form>
        </div>
      </section>

    </div>


  );
};

export default StripeForm;
