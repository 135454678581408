import React, { useEffect, useState } from 'react';
import { API_URL, SERVER_URL } from "../../config";

const FlexmlsScreen = () => {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [filesList, setfilesList] = useState([]);

  useEffect(() => {

    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(`${SERVER_URL}api/script/scrape/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setLoading(false);
          setfilesList(data.data)
        }
      } catch (error) {
        console.error('Error:', error);
        clearInterval(intervalId);
        setLoading(false);
      }
    }, 5000); // Poll every 5 seconds

    return () => clearInterval(intervalId);
  })

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${SERVER_URL}api/script/scrape/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({ url: inputValue }),
      });
      const data = await response.json();
      console.log(data); // Handle the response data as needed
    } catch (error) {
      console.error('Error:', error); // Handle the error as needed
      setLoading(false);
    }
  };

  return (
    <div className="px-5 py-7 sm:ml-64">
      <div className="border p-4 bg-white rounded-2xl">
        <div className="w-56 text-slate-950 font-medium">
          Submit URL
        </div>
        <div className="text-slate-400 text-sm font-normal">
          Enter the URL you want to scrape
        </div>
        <div className="flex justify-between items-center mt-6">
          <div className="w-56 flex-col justify-start items-start gap-4 flex">
            <input
              type="text"
              placeholder="Enter URL..."
              value={inputValue}
              className="inputField w-64 placeholder:text-slate-400"
              onChange={(e) => setInputValue(e.target.value)}
              style={{ background: "white" }}
            />
          </div>
          <button
            className="btn primaryBtn"
            onClick={handleSubmit}
            style={{
              backgroundColor: "#1E293B",
              color: "white",
              borderRadius: "8px",
              padding: "10px 20px",
            }}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="border my-2 p-4 bg-white rounded-2xl">

        <div className="flex justify-between items-center mt-6">
          <table style={{ width: "100%" }}>
            <thead>
              <td className='text-center'>
                #
              </td>
              <td className='text-center'>
                Status
              </td>
              <td className='text-center'>
                Outputs
              </td>
            </thead>
            <tbody>
              {filesList.map((elem, index) => {
                return <tr className='py-3 text-center'>
                  <td>{index + 1}</td>
                  <td>{elem.status}</td>
                  <td><a className='btn btn-sm btn-primary' href={elem.file_url}>{elem.file_url ? "Download" : ""}</a></td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
      {loading && (
        <div className="flex flex-col items-center mt-4">
          <div className="loader" style={{
            border: "4px solid #f3f3f3",
            borderRadius: "50%",
            borderTop: "4px solid #3498db",
            width: "40px",
            height: "40px",
            animation: "spin 2s linear infinite"
          }}></div>
        </div>
      )}
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

export default FlexmlsScreen;
