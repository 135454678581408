
import "./App.css";
import Sidebar from "./components/constants/Sidebar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/screens/Home";
import Welcome from "./components/screens/Welcome";
import HowItWorks from "./components/screens/HowItWorks";
import SignIn from "./components/screens/SignIn";
import SignUp from "./components/screens/SignUp";
import Demo from "./components/screens/Demo";
import MyAccount from "./components/screens/MyAccount";
import Plans from "./components/screens/pricing/Plans";
import Purchase from "./components/screens/pricing/Purchase";
import PaymentHistory from "./components/screens/pricing/PaymentHistory";
import ForgetPassword from "./components/screens/ForgetPassword";
import ResetPassword from "./components/screens/ResetPasword";
import StripeForm from "./components/screens/pricing/stripe_form";
import FlexmlsScreen from "./components/screens/FlexmlsScreen";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useState } from "react";
const stripePromise = loadStripe('pk_test_51OIyvvIfYjYcC2fuMmtts18xPcAhkW4maXCvUFosUXA31yuHEnUR64KucwPpDq5jSvWBNZPN5HRfy3XP9lpcRGdm00qMqeyz19');

function App() {
  const [product_id, setProduct_id] = useState("");
  const [amount, setamount] = useState(0);

 
  return (
    <>
      <BrowserRouter>
        <Sidebar />
        <Elements stripe={stripePromise}>
        <Routes>
          <Route exact path="/" element={<Welcome />} />
          <Route exact path="/how_it_works" element={<HowItWorks />} />
          <Route exact path="/sign_in" element={<SignIn />} />
          <Route exact path="/create_account" element={<SignUp />} />
          <Route exact path="/app_demo" element={<Demo />} />
          <Route exact path="/my_account" element={<MyAccount />} />
          <Route exact path="/birdog" element={<Home />} />
          <Route exact path="/pricing" element={<Plans set_amount={setamount}  setProduct_id={setProduct_id}/>} />
          <Route exact path="/pricing/payment" element={<Purchase product_amount={amount} product_id={product_id}/>} />
          <Route exact path="/pricing/payment_history" element={<PaymentHistory />} />
          <Route exact path="/pricing/payment_form" element={<StripeForm />} />
          <Route exact path="/forget/password" element={<ForgetPassword />} />
          <Route exact path="/reset/password" element={<ResetPassword />} />
          <Route exact path="/flexmls" element={<FlexmlsScreen />} />
         
        </Routes>
        </Elements>
      </BrowserRouter>
    </>
  );
}

export default App;
